import React from 'react';
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';

export type ManagedActionItem = {
  id: string;
  title: string;
  description?: string;
  action?: (item: ManagedActionItem) => void;
  persistent?: boolean;
};

export const ManagedActionItemsContext = React.createContext<{
  acknowledged: STRING_BOOLEAN_HASH;
  add: (item: ManagedActionItem) => void;
  clear: () => void;
  items: ManagedActionItem[];
  itemsById: Record<string, ManagedActionItem>;
  remove: (id: string) => void;
}>({
  acknowledged: {},
  add: () => {},
  clear: () => {},
  items: [],
  itemsById: {},
  remove: () => {},
});

type Props = {
  children?: React.ReactNode;
};

export const ManagedActionItemsProvider: React.FC<Props> = ({ children }) => {
  const [items, setItems] = React.useState<ManagedActionItem[]>([]);
  const [acknowledged, setAcknowledged] = React.useState<STRING_BOOLEAN_HASH>(
    {},
  );

  const add = (item: ManagedActionItem) => {
    if (acknowledged[item.id]) return;
    if (items.find((i) => i.id === item.id)) return;
    if (!item.action) {
      item.action = (item) => {
        remove(item.id);
      };
    }
    setItems((prevItems) => [...prevItems, item]);
  };

  const remove = (id: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => {
        if (item.id === id && !item.persistent) {
          setAcknowledged((prev) => ({ ...prev, [id]: true }));
        }
        return item.id !== id;
      }),
    );
  };

  const clear = () => {
    setItems([]);
  };

  const itemsById = React.useMemo(() => {
    return items.reduce(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {} as Record<string, ManagedActionItem>,
    );
  }, [items]);

  return (
    <ManagedActionItemsContext.Provider
      value={{
        acknowledged,
        add,
        clear,
        items,
        itemsById,
        remove,
      }}
    >
      {children}
    </ManagedActionItemsContext.Provider>
  );
};
