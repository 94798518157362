import { Macro } from '@chiroup/core/types/Macro';
import { Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

type Props = {
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  filteredMacros: Partial<Macro>[];
  handleMacroClick: (phrase: string, cursorPosition?: number) => void;
  cursorPosition?: number;
};

const MacrosPopover: React.FC<Props> = ({
  showPopover,
  filteredMacros,
  handleMacroClick,
  cursorPosition,
  setShowPopover,
}) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  useEffect(() => {
    if (!filteredMacros.length) {
      setShowPopover(false);
    }
  }, [filteredMacros, setShowPopover]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (showPopover) {
        if (e.code === 'Tab') {
          if (highlightedIndex !== -1) {
            e.preventDefault();
            handleMacroClick(
              filteredMacros[highlightedIndex]?.phrase ?? '',
              cursorPosition ?? 0,
            );
          }
        } else if (e.code === 'ArrowDown') {
          const macroLength = filteredMacros.length;
          e.preventDefault();
          setHighlightedIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            return newIndex + 1 > macroLength ? 0 : newIndex;
          });
        } else if (e.code === 'ArrowUp') {
          const macroLength = filteredMacros.length;
          e.preventDefault();
          setHighlightedIndex((prevIndex) => {
            const newIndex = prevIndex - 1;
            return newIndex < 0 ? macroLength - 1 : newIndex;
          });
        } else if (e.code === 'Backspace') {
          setShowPopover(false);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    highlightedIndex,
    showPopover,
    cursorPosition,
    filteredMacros,
    handleMacroClick,
    setShowPopover,
  ]);

  return (
    <div className="relative w-full">
      <Transition
        show={showPopover}
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div
          className={`origin-top-right absolute z-50 mt-2 w-full max-w-lg bg-white border border-gray-300 rounded-t-md shadow-lg flex flex-col w-full`}
          style={{
            maxHeight: 'calc(100vh - 100px)',
          }}
        >
          <p className="italic p-1 text-xs text-gray-500 b-2 flex items-center bg-gray-300 px-2 border-1 border-gray-300">
            <span>
              <InformationCircleIcon className="h-4 w-4 mr-2" />
            </span>
            <span>Click on shortcut or press tab to use macro</span>
          </p>
          <div className="p-2 max-h-48 overflow-y-scroll">
            <ul className="list-none mt-2">
              {filteredMacros?.map((macro, index) => (
                <li
                  key={index}
                  className={`cursor-pointer py-1 px-2 ${
                    index === highlightedIndex ? 'bg-gray-100' : ''
                  } rounded-md`}
                  onClick={() =>
                    handleMacroClick(macro.phrase ?? '', cursorPosition)
                  }
                >
                  {macro.shortcut}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default MacrosPopover;
