import { SELECT_OPTION_TYPE } from '../constants/globals';

export enum CodeSets {
  CPT = 'CPT',
  ICD10CM = 'ICD-10-CM',
  SERVICE = 'Service',
  DIAGNOSTIC = 'Diagnostic',
}

export type BillingCodeReference = {
  codeSet: CodeSets;
  code: string;
  clinicID?: number;
  description: string;
};

export type BillingCode = {
  ID: number;
  clinicID: number;
  codeSet: CodeSets;
  code: string;
  codeShortcut: string;
  description: string;
  billedAmount: number | string;
  variableBilledAmount: boolean;
  defaultModifiers?: string[];
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  deleted: boolean;
  payorIds?: number[];
  payors: BillingCodePayor[];
};

export type BillingCodeModifier = {
  clinicID: number;
  billingCodeID: number;
  modifier: string;
  payorID?: number;
};

export type BillingCodePayor = {
  clinicID: number;
  billingCodeID: number;
  payorID: number;
  allowedAmount: number;
  modifier1?: string;
  modifier2?: string;
  modifier3?: string;
  modifier4?: string;
  legalName?: string;
  customCode?: string;
};

export enum KnownClearingHouses {
  None = 'None',
  TriZetto = 'TriZetto',
  Infinedi = 'Infinedi',
  ClaimMD = 'ClaimMD',
  OfficeAlly = 'Office Ally',
  Waystar = 'Waystar',
  // -- new method below here!
  ApexEDI = 'Apex EDI',
  HNS = 'Health Network Solutions',
  Inovalon = 'Inovalon',
  Ability = 'Ability',
}

export const KnownClearingHouseNames = {
  [KnownClearingHouses.None]: 'None',
  [KnownClearingHouses.TriZetto]: `TriZetto Provider Solutions`,
  [KnownClearingHouses.Infinedi]: `Infinedi`,
  [KnownClearingHouses.ClaimMD]: `CLAIMMD`,
  [KnownClearingHouses.OfficeAlly]: `Office Ally`,
  [KnownClearingHouses.Waystar]: `Waystar`,
  [KnownClearingHouses.ApexEDI]: `Apex EDI`,
  [KnownClearingHouses.HNS]: `Health Network Solutions`,
  [KnownClearingHouses.Inovalon]: `Inovalon`,
  [KnownClearingHouses.Ability]: `Ability (an Inovalon Company)`,
};

/**
 * [2025-02-11.0844 by Brian] Trizetto gave us a PDF with a
 * recommended order for the REFs in loop 2300. We've made
 * that the default. Each clearinghouse can override this in
 * the KnownClearingHouseSettings object. [We found at least
 * 3 different orders, so this seemed prudent.]
 */
export const DEFAULT_LOOP_2300_REF_ORDER = ['9F', 'G1', 'F8', 'EA'];

/**
 * Ability is the first clearing house to need special handling
 * of a single field. So, the concept of a function to return it
 * was added. For now, the options is ignored, but we could add
 * anything not just a constant.
 */
export type ClearingHouseSettingsType = {
  isSinglePayorRequired?: boolean;
  useISA06forNM109?: boolean;
  useISA08forNM109?: boolean;
  LOOP_2300_REF_ORDER?: string[];
  isa14?: (options?: any) => string;
  nm109?: (options?: any) => string;
};

/**
 * These define the flags used in the EDI build process and are
 * honored by createBatchSet.ts BUT only for ones below the new
 * method above. The old method is hard-coded.
 */
export const KnownClearingHouseSettings = {
  [KnownClearingHouses.ApexEDI]: {
    useISA06forNM109: false,
    useISA08forNM109: true,
    LOOP_2300_REF_ORDER: DEFAULT_LOOP_2300_REF_ORDER,
  },
  [KnownClearingHouses.HNS]: {
    useISA06forNM109: false,
    useISA08forNM109: true,
    LOOP_2300_REF_ORDER: DEFAULT_LOOP_2300_REF_ORDER,
  },
  [KnownClearingHouses.Infinedi]: {
    useISA06forNM109: false,
    useISA08forNM109: true,
    LOOP_2300_REF_ORDER: DEFAULT_LOOP_2300_REF_ORDER,
  },
  [KnownClearingHouses.Inovalon]: {
    useISA06forNM109: false,
    useISA08forNM109: true,
    LOOP_2300_REF_ORDER: DEFAULT_LOOP_2300_REF_ORDER,
  },
  [KnownClearingHouses.Ability]: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isa14: (options?: any) => {
      return '0';
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nm109: (options?: any) => {
      return 'ABILITY837';
    },
    LOOP_2300_REF_ORDER: DEFAULT_LOOP_2300_REF_ORDER,
  } as ClearingHouseSettingsType,
} as { [key in KnownClearingHouses]?: ClearingHouseSettingsType };

export const KnownClearingHouseOptions = Object.keys(
  KnownClearingHouses,
).reduce((a, c) => {
  a.push({
    text: c,
    value: c,
  });
  return a;
}, [] as SELECT_OPTION_TYPE[]);

export type BillingProfile = {
  ID?: number;
  active: 0 | 1;
  type: 'Organization' | 'Individual';
  name: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  suffix?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  extension?: string;
  email?: string;
  contactName?: string;
  clearinghouse?: KnownClearingHouses;
  taxIdType: 'EIN' | 'SSN';
  taxId?: string;
  npi?: number;
  sameAsBillingProviderInfo?: boolean;
  taxonomyCode?: string;
  payToNonPersonEntity?: boolean;
  payToPerson?: boolean;
  payToAddressLine1?: string;
  payToAddressLine2?: string;
  payToCity?: string;
  payToState?: string;
  payToZipCode?: string;
  submitterType?: string;
  submitterName?: string;
  submitterFirstName?: string;
  submitterMiddleInitial?: string;
  submitterLastName?: string;
  submitterPhoneNumber?: string;
  submitterExtension?: string;
  submitterEmail?: string;
  submitterContactName?: string;
  providerDesignatedUsers?: string[];
  deleted: number;
  description?: string;
  trizettoUsername?: string;
  trizettoPassword?: string;
};
