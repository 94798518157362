import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ReactNode, useId } from 'react';
import { Tooltip } from 'react-tooltip';

type Props = {
  addTipClassName?: string;
  component?: ReactNode;
  containerClassName?: string;
  iconClassName?: string;
  iconColorClassName?: string;
  iconSizeClassName?: string;
  iconStyle?: React.CSSProperties;
  id?: string;
  nullOnEmpty?: boolean;
  svgClassName?: string;
  text?: string;
  tipClassName?: string;
  type?: string;
};

/**
 * [2023-09-06.1115 by Brian]
 * Added support for 'lr-field-error' or "lower-right field error" for use
 * with the useForm hook to show errors in a more discrete way for tight
 * form layouts.
 *
 * [2023-08-25.0923 by Brian]
 * Simplifies adding standardized tooltips. Mostly, just need the text, but
 * it is configurable via the other props.
 *
 * Examples:
 *
 *   <TrivialTooltip text={tooltip} />
 *
 *   <TrivialTooltip text={tooltip} type="info" />
 *
 *   <TrivialTooltip text={tooltip} type="info" id="main-tooltip" />
 *
 * This will make more objects in the DOM. You could consider using the shared
 * app- or portal-wide tooltips instead by passing the 'id' of the pre-defined
 * tooltips: 'main-tooltip' and 'slideover-tooltip' are examples.
 *
 * Using individual tooltips is more flexible. For instance, the width of the
 * hovers can be controlled with class names.
 *
 * Parameters:
 *
 * nullOnEmpty: boolean (Default true)
 *
 *   Pass this boolean to control if the tip appears or not. This is useful
 *   when the text may or may not be there and you don't want anything
 *   rendered if the tooltip text is empty.
 *
 * @param param0
 * @returns
 */
export const TrivialTooltip: React.FC<Props> = ({
  text,
  component = null,
  iconColorClassName = 'text-gray-400',
  iconSizeClassName = 'h-3 w-3',
  iconClassName = `${iconSizeClassName} ${iconColorClassName} inline-block ml-2 align-text-top`,
  tipClassName = 'w-56',
  containerClassName = 'inline-block',
  // svgClassName,
  type = 'question',
  id,
  iconStyle,
  nullOnEmpty = true,
  addTipClassName = '',
}) => {
  const reactId = useId();
  const newToolTip = !id;
  const toolTipId = newToolTip ? `trivial-tooltip-${reactId}` : id;

  if (nullOnEmpty && !text && !component) return null;

  if (type === 'lr-field-error') {
    containerClassName = '';
    tipClassName = 'w-56 text-white bg-red-500 rounded-md';
  }

  const icon = () => {
    switch (type) {
      case 'lr-field-error': {
        return (
          <div
            className="absolute z-50"
            style={iconStyle || { bottom: '-1.22rem', right: '-0.61rem' }}
          >
            <svg
              className="h-5 w-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      }
      case 'info': {
        return (
          <InformationCircleIcon className={iconClassName} id={toolTipId} />
        );
      }
      case 'warn': {
        return (
          <ExclamationTriangleIcon className={iconClassName} id={toolTipId} />
        );
      }
      default: {
        return (
          <QuestionMarkCircleIcon className={iconClassName} id={toolTipId} />
        );
      }
    }
  };

  return (
    <div
      className={containerClassName}
      data-tooltip-id={toolTipId}
      data-tooltip-content={text}
      data-tooltip-place="top"
    >
      {icon()}
      {newToolTip && (
        <Tooltip
          id={toolTipId}
          className={classNames(tipClassName, addTipClassName)}
          style={{ zIndex: 99 }}
        >
          {component ? component : <div>{text}</div>}
        </Tooltip>
      )}
    </div>
  );
};
