import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import React from 'react';

type Props = {
  errors?: FormError;
  className?: string;
  nullOnEmpty?: boolean;
};

export const FieldErrors: React.FC<Props> = ({
  errors,
  className = '',
  nullOnEmpty = false,
}) => {
  if ((nullOnEmpty && !errors) || !errors?.message?.trim()) return null;

  return errors ? (
    <p
      className={['mt-2 text-sm text-red-600', className].join(' ')}
      id="email-error"
    >
      {errors.message}
    </p>
  ) : null;
};
