import Decimal from 'decimal.js';

Decimal.set({
  rounding: 7,
});

export const createDecimal = (value: number | string | Decimal): Decimal => {
  let valueToUse = typeof value === 'string' ? value.replace(/,/g, '') : value;
  valueToUse = valueToUse === '' ? 0 : valueToUse;
  return new Decimal(valueToUse || 0);
};
