import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { ChangeEvent, Fragment, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Loading } from './Loading';
import { TrivialTooltip } from './TrivialTooltip';

export enum ButtonColors {
  primary = 'primary',
  red = 'red',
  plain = 'plain',
  plainWithBorder = 'plainWithBorder',
  plainIcon = 'plainIcon',
  plainTransparent = 'plainTransparent',
  accent = 'accent',
}

type ButtonClassValues = {
  always: string;
  disabled: string;
  enabled: string;
};

type ButtonClasses = {
  [key in ButtonColors]: ButtonClassValues; // Note that "key in".
};

const buttonClasses: ButtonClasses = {
  primary: {
    always:
      'border border-transparent text-sm leading-5 font-medium text-white py-2 px-4',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled:
      'text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700',
  },
  red: {
    always:
      'border border-transparent text-sm leading-5 font-medium text-white py-2 px-4',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled:
      'text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red active:bg-red-700',
  },
  plain: {
    always:
      'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-white dark:bg-darkGray-700 hover:text-gray-500 dark:hover:text-darkGray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-700 transition duration-150 ease-in-out',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled: '',
  },
  plainTransparent: {
    always:
      'inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-transparent hover:text-gray-500 dark:hover:text-darkGray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-700 transition duration-150 ease-in-out',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled: '',
  },
  plainWithBorder: {
    always:
      'border border-gray-300 dark:border-darkGray-900 inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 dark:bg-darkGray-700',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled:
      'bg-white dark:bg-darkGray-800 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-700 transition duration-150 ease-in-out hover:text-gray-500 dark:hover:text-darkGray-300',
  },
  plainIcon: {
    always:
      'inline-flex items-center text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400 bg-transparent hover:text-gray-500 dark:hover:text-darkGray-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 dark:active:bg-darkGray-700 transition duration-150 ease-in-out',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 cursor-not-allowed',
    enabled: '',
  },
  accent: {
    always:
      'border border-transparent text-sm leading-5 font-medium text-white py-2 px-4',
    disabled:
      'text-gray-500 bg-gray-300 dark:bg-darkGray-400 dark:text-gray-300 cursor-not-allowed',
    enabled:
      'text-white bg-accent-600 hover:bg-accent-400 focus:outline-none focus:border-accent-700 focus:ring-accent active:bg-accent-700',
  },
};

type tooltipOptions = {
  text?: string;
  iconColorClassName?: string;
  type?: string;
  iconSizeClassName?: string;
  iconClassName?: string;
  tipClassName?: string;
  containerClassName?: string;
  svgClassName?: string;
  id?: string;
  iconStyle?: any;
  nullOnEmpty?: boolean;
};

type Props = {
  text?: string;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  color?: ButtonColors;
  icon?: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  extraOptions?: { text: string; onClick: (e?: any) => void }[];
  tooltip?: string;
  fileUpload?: boolean;
  onSelectFile?: (e: ChangeEvent<HTMLInputElement>) => void;
  badge?: number | string;
  autoFocus?: boolean;
  tooltipClassName?: string;
  tooltipIconClassName?: string;
  trivialTooltip?: tooltipOptions;
  omitWhenDisabled?: boolean;
};

const defaultTrivialTooltipOptions: tooltipOptions = {
  tipClassName: 'text-left w-56',
  iconColorClassName: 'text-white',
  type: 'warn',
  iconSizeClassName: 'h-4 w-4',
};

export const Button: React.FC<Props> = ({
  text,
  label,
  onClick,
  disabled,
  loading,
  fullWidth,
  fullHeight,
  color = ButtonColors.primary,
  icon,
  className,
  type = 'button',
  extraOptions,
  tooltip,
  fileUpload,
  onSelectFile,
  badge,
  autoFocus,
  tooltipClassName,
  tooltipIconClassName = 'h-3 w-3 text-white inline-block ml-2 align-text-top',
  trivialTooltip,
  omitWhenDisabled,
}) => {
  text = text || label;
  const activeTrivialTooltipOptions = {
    ...defaultTrivialTooltipOptions,
    ...trivialTooltip,
  };

  const fileInput = useRef<HTMLInputElement | null>(null);
  const buttonClass = buttonClasses[color];
  const id = uuidv4();
  const onClickStep = (e: any) => {
    if (fileUpload) {
      fileInput.current?.click();
    } else {
      onClick?.(e);
    }
  };

  if (omitWhenDisabled && disabled) {
    return null;
  }

  return !!extraOptions?.length && !onClick ? (
    <div>
      <Menu
        as="div"
        id={`tt-${id}`}
        className={[
          '-ml-px relative block',
          fullWidth ? 'w-full' : '',
          fullHeight ? 'h-full' : '',
        ].join(' ')}
      >
        <Menu.Button
          type={type}
          className={[
            'inline-flex justify-center items-center py-2 pl-4 pr-2 rounded-md',
            buttonClass.always,
            disabled ? buttonClass.disabled : buttonClass.enabled,
            fullWidth ? 'w-full' : '',
            fullHeight ? 'h-full' : '',
            className,
          ].join(' ')}
          disabled={disabled || loading}
        >
          {loading && <Loading className="-ml-1 mr-3 w-4 h-4" />}
          {text}
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {extraOptions.map((opt, i) => (
                <Menu.Item key={i}>
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-darkGray-200 hover:bg-gray-100 dark:hover:bg-darkGray-900 hover:text-gray-900 dark:hover:text-darkGray-200 cursor-pointer"
                    onClick={() => {
                      if (disabled) return;
                      opt.onClick();
                    }}
                  ></div>
                  {opt.text}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  ) : (
    <label
      className={[
        'relative inline-flex',
        fullWidth ? 'w-full' : '',
        fullHeight ? 'h-full' : '',
        icon && !text ? 'h-5' : '',
      ].join(' ')}
      htmlFor="file-upload"
    >
      <button
        autoFocus={autoFocus}
        type={type}
        onClick={onClickStep}
        className={[
          'inline-flex justify-center items-center relative',
          extraOptions?.length ? 'rounded-l-md' : 'rounded-md',
          buttonClass.always,
          disabled ? buttonClass.disabled : buttonClass.enabled,
          fullWidth ? 'w-full' : '',
          fullHeight ? 'h-full' : '',
          className,
        ].join(' ')}
        disabled={disabled || loading}
      >
        {!!badge && (
          <span className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-black bg-white border-2 border-gray-300 rounded-full -top-2 -right-2 dark:border-gray-900">
            {badge}
          </span>
        )}
        {loading ? (
          <Loading
            color="text-gray-500 dark:text-darkGray-400"
            className="-ml-1 mr-3"
            size={5}
          />
        ) : icon ? (
          <svg
            className={['h-5 w-5', text ? '-ml-1 mr-2' : ''].join(' ')}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            {icon}
          </svg>
        ) : null}
        {text}
        {!!tooltip && (
          <TrivialTooltip
            text={tooltip}
            iconClassName={tooltipIconClassName}
            tipClassName={tooltipClassName || 'w-64 text-left text-sm'}
          />
        )}
        <TrivialTooltip {...activeTrivialTooltipOptions} />
      </button>
      {!!extraOptions?.length && (
        <Menu as="div" className="-ml-px relative block">
          <Menu.Button
            type="button"
            className={[
              'relative inline-flex items-center px-2 py-2 rounded-r-md text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 h-full',
              buttonClass.always,
              disabled ? buttonClass.disabled : buttonClass.enabled,
              className,
              color === ButtonColors.primary
                ? 'border-r-0 border-t-0 border-b-0 border-l border-white'
                : 'border border-gray-300',
            ].join(' ')}
            disabled={disabled || loading}
          >
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 mt-3 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {extraOptions.map((opt, i) => (
                  <Menu.Item key={i}>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                      onClick={() => {
                        opt.onClick();
                      }}
                    >
                      {opt.text}
                    </div>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {fileUpload && (
        <input
          ref={fileInput}
          id="file-upload"
          name="file-upload"
          type="file"
          className="sr-only"
          onChange={onSelectFile}
        />
      )}
    </label>
  );
};
