import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import TimePicker from 'intergalactic/time-picker';
import React, { useEffect, useState } from 'react';
import { FieldErrors } from './FieldErrors';

dayjs.extend(utc);
dayjs.extend(tz);

type Props = {
  label?: string;
  value?: Dayjs;
  onChange: (val: Dayjs) => void;
  timezone?: string;
  errors?: { message?: string };
  // localErrors?: string;
  setFormErrors?: (val: string) => void;
  timeInterval?: number;
  setHasError?: (val: boolean) => void;
  hideError?: boolean;
};

export const TimeSelect: React.FC<Props> = ({
  label = '',
  value,
  onChange,
  timezone = 'America/New_York',
  errors,
  setFormErrors,
  timeInterval = 15,
  setHasError,
  hideError = false,
}) => {
  const [localTimeValue, setLocalTimeValue] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [localErrors, setLocalErrors] = useState('');

  useEffect(() => {
    if (value) {
      const valueAsString = value?.format('HH:mm');
      if (valueAsString !== localTimeValue) {
        const timeString = value.format('HH:mm');
        setLocalTimeValue(timeString);
      }
    }
  }, [localTimeValue, value, onChange]);

  const handleTimeChange = (val: string) => {
    const [hours, minutesSingle] = val.split(':');
    const minutes =
      minutesSingle?.length === 1 ? `0${minutesSingle}` : minutesSingle;
    const newValue = `${hours}:${minutes}`;
    setLocalTimeValue(newValue);
    const dayjsVal = dayjs.tz(`2000-01-01 ${newValue}`, timezone);
    onChange(dayjsVal);
  };

  const handleBlur = () => {
    const indexOfColon = localTimeValue.indexOf(':');
    if (indexOfColon === 0 || indexOfColon === localTimeValue.length - 1) {
      setLocalErrors?.('Invalid time format. Please use HH:MM');
      setFormErrors?.('Invalid time format. Please use HH:MM');
      setShowError(true);
      setHasError?.(true);
      return;
    }

    if (!value) {
      setLocalErrors?.('Time is required');
      setFormErrors?.('Time is required');
      setShowError(true);
      setHasError?.(true);
      return;
    }

    setLocalErrors?.('');
    setFormErrors?.('');
    setShowError(false);
    setHasError?.(false);
  };

  return (
    <div>
      {!!label && (
        <p className="mb-1 block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200">
          {label}
        </p>
      )}
      <TimePicker
        className="border border-gray-300 rounded-md shadow-sm"
        disabled={false}
        is12Hour={true}
        size="l"
        state={showError ? 'invalid' : 'normal'}
        value={localTimeValue}
        onChange={handleTimeChange}
        onBlur={handleBlur}
      />
      {showError && (localErrors || errors?.message) && !hideError && (
        <FieldErrors
          errors={{ message: localErrors || errors?.message || '' }}
        />
      )}
    </div>
  );
};

export default TimeSelect;
