import React, {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Loading } from '../Loading';
import { TrivialTooltip } from '../TrivialTooltip';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';
import MacrosPopover from './MacrosPopover';
import useMacros from './useMacros';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '@chiroup/core/functions/classNames';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { Macro } from '@chiroup/core/types/Macro';

type InputRef = {
  focus: () => void;
  blur: () => void;
  // Add any other methods or properties you want to expose
};

type Props = {
  name: string;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  labelClassName?: string;
  value?: string | number | null;
  onChange?: (val: any) => void;
  onBlur?: (val: string) => void;
  errors?: FormError;
  disabled?: boolean;
  onFocus?: () => void;
  type?: string;
  hint?: string | React.ReactNode;
  hintOnClick?: () => void;
  autoComplete?: string;
  inputClassName?: string;
  placeholder?: string;
  min?: string;
  max?: string;
  step?: string;
  clickIcon?: boolean;
  autoFocus?: boolean;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  pattern?: string;
  loading?: boolean;
  inputStyle?: { [key: string]: string | number };
  maxLength?: number;
  iconRight?: boolean;
  onEnter?: () => void;
  macros?: Partial<Macro>[];
  downError?: string;
  initialValue?: number;
  tooltip?: string;
  tooltipClassName?: string;
  tooltipId?: string;
  inputTitle?: string;
  labelTitle?: string;
  /**
   * The 'inline' is special-purpose for use by HC. It may not work for
   * anything else. User be warned.
   */
  inline?: boolean;
  inlineLabelWidth?: string;
  inlineInputWidth?: string;
  disabledClassName?: string;
  hidden?: boolean;
  xout?: (() => void) | null;
  componentLabelRight?: React.ReactNode;
  hintUnderLabel?: boolean;
};

export const Input = forwardRef<InputRef, Props>(
  (
    {
      name,
      label,
      value,
      onChange,
      onBlur,
      errors,
      className = '',
      icon,
      disabled = false,
      onFocus,
      type = 'text',
      hint,
      hintOnClick,
      autoComplete,
      inputClassName,
      inputStyle = {},
      placeholder,
      min,
      max,
      step,
      clickIcon,
      autoFocus = false,
      inputMode = 'text',
      pattern,
      loading,
      maxLength,
      iconRight,
      labelClassName = 'block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2',
      macros,
      downError,
      initialValue,
      tooltip,
      tooltipId,
      tooltipClassName,
      inputTitle,
      inline,
      inlineLabelWidth,
      inlineInputWidth,
      labelTitle,
      hidden = false,
      disabledClassName = 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed',
      xout = null,
      componentLabelRight,
      hintUnderLabel = false,
    }: Props,
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      blur: () => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      },
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
      // You can expose other properties or methods as needed
    }));

    const [showPopover, setShowPopover] = useState(false);
    const { filteredMacros } = useMacros(value as string, macros);

    const handleMacroClick = (phrase: string, cursorPlacement?: number) => {
      if (
        typeof value === 'string' &&
        typeof cursorPlacement === 'number' &&
        inputRef.current
      ) {
        const cutString = value.slice(0, cursorPlacement);
        const lastSlashIndex = cutString.lastIndexOf('/');

        const newStr = `${value.slice(
          0,
          lastSlashIndex,
        )}${phrase} ${value.slice(cursorPlacement)}`;

        onChange?.(newStr);
        setShowPopover(false);

        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.selectionStart =
              lastSlashIndex + phrase.length + 1;
            inputRef.current.selectionEnd = lastSlashIndex + phrase.length + 1;
          }
        }, 0);
      }
    };
    const [showDownError, setShowDownError] = React.useState<boolean>(false);
    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
      let val: string | number | null = e?.target?.value;
      if (type === 'number' && val.toString()?.length > 9) return;
      if (type === 'number') {
        setShowDownError(false);
        val = isEmpty(val) ? null : parseInt(val);
        if (downError && (val || 0) < Number(initialValue)) {
          setShowDownError(true);
          return;
        }
      }
      if (max && val && Number(val) > Number(max)) {
        val = parseInt(max);
      }

      onChange?.(val);
    };

    const onBlurValue = (e: ChangeEvent<HTMLInputElement>) => {
      const targetValue = e?.target?.value;
      onBlur?.(targetValue as string);
    };

    const onKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement>,
      value?: any,
    ) => {
      if (
        type === 'number' &&
        !/[0-9]/.test(e.key) &&
        typeof value === 'number' &&
        (e.key === 'Backspace' || e.key === 'Delete')
      ) {
        if (value === 0) {
          onChange?.(null);
        } else {
          return;
        }
      }
      if (macros?.length) {
        if (e.code === 'Slash') {
          setShowPopover(true);
        } else if (e.code === 'Space') {
          setShowPopover(false);
        }
      } else if (
        type === 'number' &&
        !/[0-9]/.test(e.key) &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete' &&
        e.key !== 'Tab' &&
        e.key !== 'ArrowLeft' &&
        e.key !== 'ArrowRight'
      ) {
        e.preventDefault();
      }
    };

    // useEffect(() => {
    //   const inputElement = inputRef.current;
    //   if (inputElement) {
    //     inputElement.addEventListener('wheel', handleWheel);
    //   }

    //   // Cleanup event listener on component unmount
    //   return () => {
    //     if (inputElement) {
    //       inputElement.removeEventListener('wheel', handleWheel);
    //     }
    //   };
    // }, []); // run only once

    // const handleWheel = (e: WheelEvent) => {
    //   if (document.activeElement === inputRef.current) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    // };

    return hidden ? null : (
      <div className={className}>
        {label && (
          <div
            className={classNames(
              inlineLabelWidth ?? '',
              inline && 'inline-block',
            )}
          >
            <label
              htmlFor={name}
              className={classNames(
                labelClassName,
                componentLabelRight ? 'flex flex-row justify-between' : '',
              )}
              title={labelTitle}
            >
              {label}
              {tooltip && (
                <TrivialTooltip
                  text={tooltip}
                  type="info"
                  id={tooltipId}
                  svgClassName="w-3 h-3 text-grey-400 ml-2"
                />
              )}
              {componentLabelRight ? componentLabelRight : null}
            </label>
          </div>
        )}
        <div
          className={classNames(
            inlineInputWidth ?? '',
            inline && 'inline-block',
          )}
        >
          <div className="relative">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              {icon && (
                <div
                  className={[
                    `absolute inset-y-0 ${
                      iconRight ? 'right-4' : 'left-0'
                    } pl-3 flex items-center z-20`,
                    clickIcon ? '' : 'pointer-events-none',
                  ].join(' ')}
                >
                  {icon}
                </div>
              )}
              <input
                id={name}
                name={name}
                title={inputTitle}
                className={[
                  'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2',
                  errors
                    ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red-500 outline-none'
                    : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  disabled ? disabledClassName : '',
                  inputClassName,
                  icon && !iconRight
                    ? typeof icon === 'string'
                      ? 'pl-5'
                      : 'pl-10'
                    : '',
                ].join(' ')}
                value={value ?? ''}
                data-cy={placeholder}
                onChange={onChangeValue}
                onBlur={onBlurValue}
                onFocus={onFocus}
                type={type}
                disabled={disabled}
                ref={inputRef}
                autoComplete={autoComplete}
                placeholder={placeholder}
                min={min}
                max={max}
                step={step}
                autoFocus={autoFocus}
                inputMode={
                  inputMode ? inputMode : type === 'number' ? 'numeric' : 'text'
                }
                pattern={
                  pattern ? pattern : type === 'number' ? '[0-9.]+' : undefined
                }
                style={inputStyle}
                maxLength={maxLength}
                onKeyDown={(e) => onKeyDown(e, value)}
              />
              {!!hint && hintUnderLabel && (
                <p
                  className={[
                    'text-sm',
                    hintOnClick && !disabled
                      ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                      : 'text-gray-500',
                  ].join(' ')}
                  id={`${name}-hint`}
                  onClick={hintOnClick}
                >
                  {hint}
                </p>
              )}
              {value && xout ? (
                <div
                  className="absolute top-3 right-2 w-4 h-4 text-gray-500 cursor-pointer"
                  onClick={(e) => {
                    if (e && e.stopPropagation) e.stopPropagation();
                    xout?.();
                  }}
                >
                  <XMarkIcon />
                </div>
              ) : null}
              {errors && <ErrorIcon />}
              {loading && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <Loading color="text-gray-400" size={6} />
                </div>
              )}
            </div>
          </div>
          <FieldErrors errors={errors} />
          {showDownError && !!downError && (
            <FieldErrors
              errors={{ message: downError }}
              className="text-xs leading-4"
            />
          )}
          {!!hint && !hintUnderLabel && (
            <p
              className={[
                'text-sm',
                hintOnClick && !disabled
                  ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                  : 'text-gray-500',
              ].join(' ')}
              id={`${name}-hint`}
              onClick={hintOnClick}
            >
              {hint}
            </p>
          )}
          {!!(showPopover && macros?.length) && (
            <MacrosPopover
              showPopover={showPopover}
              setShowPopover={setShowPopover}
              filteredMacros={filteredMacros}
              handleMacroClick={handleMacroClick}
              cursorPosition={inputRef.current?.selectionStart ?? 0}
            />
          )}
        </div>
      </div>
    );
  },
);
